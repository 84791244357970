<messages>["./DomainStatistics"]</messages>

<template>
  <v-row class="pt-6">
    <v-col cols="12">
      <h2 v-text="$t(`intro.head.${statistics.scope}`)"/>
      <p v-text="$t('intro.details', {...statistics, date: formatDateShort (statistics.referenceDate)})"/>
    </v-col>
    <v-col v-if="domains && domains.length">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="domains"
        sort-by="tld"
        :items-per-page="-1"
        :no-results-text="noResultsText (false)"
        class="elevation-1">
        <template #item="props">
          <tr>
            <td v-text="props.item.tld"/>
            <td v-text="props.item.count"/>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import {noResultsText} from '@/app/core/mixins/PaginationComponent'

  export default {
    name: 'DomainStatisticsTable',

    props: {
      statistics: {
        type: Object,
        default: () => {
        }
      }
    },

    computed: {
      headers () {
        return [
          'tld',
          'count'
        ].map (value => ({
          text: this.$t (`label.${value}`),
          value
        }))
      },

      domains () {
        const map = this.statistics.domainCountPerTld
        return Object.keys (map).map (tld => ({tld, count: map[tld]}))
      }
    },

    methods: {
      // default text properties, which can be used for `VDataTable` component
      noResultsText
    }
  }
</script>
