<messages>["./DomainStatistics"]</messages>

<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-card class="search-result mb-6">
        <v-card-title primary-title>
          <v-row>
            <v-col cols="12" sm="6">
              <div
                v-t="'title'"
                class="text-h5"/>
              <div
                v-t="'subTitle'"
                class="cgwng-subheading"/>
            </v-col>
            <v-col cols="12" sm="6">
              <client-select
                v-if="isClientSelectable"
                v-model="clientId"
                for-view
                :disabled="isLoading"
                :additional-items="allClients"/>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- list -->
        <v-card-text>
          <loading-error-component :error="error" :error-text="errorText" :is-loading="isLoading">
            <div v-if="stats.length">
              <domain-statistics-table
                v-for="s in stats"
                :key="s.scope"
                :statistics="s"/>
            </div>
            <p
              v-else
              v-t="'nostats'"/>
          </loading-error-component>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientSelect from '@/app/core/components/ClientSelect'
  import DomainStatisticsTable from '@/app/pages/Statistics/DomainStatisticsTable'
  import LoadingErrorComponent from '@/app/core/components/LoadingErrorComponent'

  import {mapGetters, mapActions} from 'vuex'
  import errorTranslator from '@/app/services/errortranslator'

  export default {
    name: 'DomainStatistics',

    components: {
      DomainStatisticsTable,
      BaseLayout,
      ClientSelect,
      LoadingErrorComponent
    },

    data () {
      return {
        isLoading: false,
        clientId: this.mayManageAllObjects ? -1 : this.actingClientId,
        stats: [],
        error: false,
        errorText: undefined
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'actingClientId',
        'mayManageForeignObjects',
        'mayManageAllObjects'
      ]),

      isClientSelectable () {
        return this.mayManageForeignObjects
      },

      allClients () {
        return this.mayManageAllObjects
          ? [{
            text: this.$t ('label.allClients'),
            value: -1
          }]
          : []
      }
    },

    watch: {
      clientId: {
        handler (newValue) {
          if (newValue) {
            this.loadStatistics ()
          } else {
            this.clientId = !this.mayManageAllObjects ? this.actingClientId : -1
          }
        },
        immediate: true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      setErrorWhileLoading (error) {
        this.error = error
      },

      loadStatistics () {
        this.isLoading = true
        this.stats = []
        this.setErrorWhileLoading (false)

        this.fetchData ({
          op: 'statistics/domain',
          params: {
            id: this.clientId
          },
          cb: data => {
            const responseData = data.data
            if (responseData?.length) {
              this.stats = responseData
            }
          },
          cbError: data => {
            if (data?.result?.[0]?.code === 'error/server/processing') {
              this.errorText = this.$t ('label.error')
            } else {
              this.errorText = errorTranslator (data)
            }
            this.setErrorWhileLoading (true)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
